import { ModelType } from '@/app/base/schemas/BaseSchema';
import EndpointFactory from '@/app/factories/EndpointFactory';
import CalendarEventSchema from '@/app/planning/schemas/CalendarEventSchema';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import allLocales from '@fullcalendar/core/locales-all';
import DateClicking from '@bwobbones/fullcalendar5-rightclick';
import interactionPlugin from '@fullcalendar/interaction';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import moment from '~/utils/moment';
import PlanningItemCollection from '@/app/planning/collections/PlanningItemCollection';
import WorksitePhaseSchema from '@/app/worksite/schemas/WorksitePhaseSchema';
import TaskSchema from '@/app/task/schemas/TaskSchema';
import PlanningItem from '@/app/planning/models/PlanningItem';
import { CalendarEventTypeValue } from '@/app/planning/enums/CalendarEventType';

export default function useFullCalendar(options: object = {}) {
    const { loading, load, filters, collection, calendarEvents, getWorksitePhasesForDay, getTasksForDay, getCalendarEventsForUserAndDay } = useGetPlanningItems(
        [
            {
                key: 'users',
            },
            {
                key: 'worksites',
            },
            {
                key: 'start',
                defaultValue: moment().startOf('isoWeek').toDateString(),
                transform: (value: string) => moment(value),
            },
            {
                key: 'end',
                defaultValue: moment().endOf('isoWeek').toDateString(),
                transform: (value: string) => moment(value),
            },
            {
                key: 'view',
                defaultValue: 'timeGridWeek',
            },
            {
                key: 'calendarEventTypes',
                defaultValue: CalendarEventTypeValue.join(','),
            },
        ],
        undefined,
        (collection: PlanningItemCollection) => {
            calendarOptions.events = collection.toFullCalendarEvents();
            collection.onSubCollectionChange = () => {
                calendarOptions.events = collection.toFullCalendarEvents();
            };
            return collection;
        }
    );

    const handleDatesSet = (args) => {
        const hasChanged = filters.get('start')?.toDateString() !== moment(args.startStr).toDateString() || filters.get('end')?.toDateString() !== moment(args.endStr).toDateString();

        filters.set('start', moment(args.startStr).toDateString(), true, false);
        filters.set('end', moment(args.endStr).toDateString(), true, false);

        if (calendarApi.value?.view?.type && filters.get('view') !== calendarApi.value?.view?.type) {
            filters.set('view', calendarApi.value?.view?.type, true, false);
        }

        if (hasChanged && filters.onUpdateCallback) {
            filters.onUpdateCallback();
        }
    };

    const handleSelect = (args) => {
        console.log('select', args);
        useEvent('planning:calendar-event:form-modal:open', {
            fields: {
                startsAt: moment(args.startStr),
                endsAt: moment(args.endStr),
            },
        });
    };

    const onEventResize = (args) => {
        console.log('On event resize', args);

        const event = getModelFromEvent(args.event);
        console.log('On event resize', args, event, event?.modelType);
        if (event?.modelType == 'calendar-event') {
            const endpoint = EndpointFactory.make(ModelType.CALENDAR_EVENTS);
            const schema = CalendarEventSchema.make({
                id: event?.model?.getId(),
                attributes: {
                    startsAt: moment(args.event.startStr).toUtcDateTimeString(),
                    endsAt: moment(args.event.endStr).toUtcDateTimeString(),
                    timetrackingAllowed: !!event?.model?.timetrackingAllowed,
                    accountedForWeeklyHours: !!event?.model?.accountedForWeeklyHours,
                },
            });
            endpoint.update(schema);
        }

        if (event?.modelType == 'worksite-phase') {
            const endpoint = EndpointFactory.make(ModelType.WORKSITE_PHASES);
            const schema = WorksitePhaseSchema.make({
                id: event?.model?.getId(),
                attributes: {
                    startsAt: moment(args.event.startStr).toUtcDateTimeString(),
                    endsAt: moment(args.event.endStr).toUtcDateTimeString(),
                },
            });
            endpoint.update(schema);
        }

        if (event?.modelType == 'task') {
            console.log(args.event.startStr, args.event.endStr, moment(args.event.startStr).toDateString(), moment(args.event.endStr).toDateString());
            if (args.event.endStr && moment(args.event.startStr).toDateString() !== moment(args.event.endStr).toDateString()) {
                useToasteoError('You cannot set a task on multiple days. (t)');
                args.revert();
                return;
            }

            const endpoint = EndpointFactory.make(ModelType.TASKS);
            const schema = TaskSchema.make({
                id: event?.model?.getId(),
                attributes: {
                    dueDate: moment(args.event.startStr).toDateString(),
                },
            });
            endpoint.update(schema);
        }
    };

    const myInteractionPlugin = new Proxy(interactionPlugin, {
        get(target, prop, receiver) {
            if (prop === 'componentInteractions') {
                target.componentInteractions[0] = DateClicking;
            }
            return Reflect.get(...arguments);
        },
    });

    const getAvailableHeight = () => {
        if (document.getElementById('full-calendar-container')) {
            return document.getElementById('full-calendar-container').clientHeight;
        }
        return window.innerHeight - 60 - 32;
    };

    const getAvailableHeightOrMinimum = () => {
        return Math.max(600, getAvailableHeight());
    };

    const renderTimeGridWeekHeader = ({ date, isToday }) => {
        const momentDate = moment(date);
        let circleClass = 'h-10 w-10 flex justify-center items-center rounded-full mb-2';
        circleClass += isToday ? ' bg-primary' : '';
        const dayClass = `text-2xl font-thin ${isToday ? 'text-white' : 'text-gray-600'}`;
        return {
            html: `<div>
                            <span class="block text-gray-500 uppercase text-2xs">${momentDate.localizedFormat('ddd')}</span>
                            <span class="${circleClass}">
                                <span class="${dayClass}">${momentDate.localizedFormat('D')}</span>
                            </span>
                        </div>`,
        };
    };

    const renderDayInMonthView = ({ date, isToday }) => {
        if (isToday) {
            const link = document.createElement('a');
            link.classList = 'h-10 w-10 flex justify-center items-center cursor-pointer rounded-full bg-primary text-white font-bold';
            link.innerText = new Date().getDate();
            link.addEventListener('mousedown', (e) => {
                e.stopPropagation();
                e.preventDefault();
                this.calendarGoToDate(date).changeCalendarView('timeGridDay');
            });

            return { domNodes: [link] };
        }
    };

    const defaultOptions = {
        schedulerLicenseKey: '0250468762-fcs-1709831423',
        locale: useGetCurrentMomentLocale(),
        locales: allLocales,
        plugins: [myInteractionPlugin, dayGridPlugin, timeGridPlugin, multiMonthPlugin, scrollGridPlugin],
        initialView: filters.get('view') ?? 'timeGridWeek',
        initialDate: filters.get('start')?.toDate() ?? moment().toDate(),
        headerToolbar: {
            start: 'prev,next title',
            center: '',
            end: 'today timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear',
        },
        views: {
            timeGridWeek: {
                dayHeaderFormat: { weekday: 'short', day: 'numeric', omitCommas: true },
                dayHeaderContent: renderTimeGridWeekHeader,
            },
            dayGridMonth: {
                titleFormat: { month: 'short', year: 'numeric' },
            },
        },
        eventColor: null,
        eventTextColor: null,
        editable: true,
        selectable: true,
        allDaySlot: true,
        allDayText: ' ',
        scrollTime: '06:00:00',
        dayMinWidth: 200,
        selectionColors: {
            visible: 'rgba(43, 211, 245, 0.3)',
            hidden: 'rgba(43, 211, 245, 0)',
        },
        slotLabelInterval: '01:00',
        slotLabelFormat: {
            hour: '2-digit',
            minute: '2-digit',
        },
        longPressDelay: 400,
        height: getAvailableHeightOrMinimum(),
        nowIndicator: true,
        handleWindowResize: true,
        datesSet: handleDatesSet,
        // dateClick: handleDateClick,
        select: handleSelect,
        selectAllow: (selectInfo) => {
            return selectInfo.start.getDay() === selectInfo.end.getDay();
        },
        eventDidMount: (args) => {
            args.el.addEventListener('contextmenu', (e) => {
                e.preventDefault();
                const event = getModelFromEvent(args.event);
                event?.model?.openContextMenu(e);
            });
        },
        eventClick: (args) => {
            const event = getModelFromEvent(args.event);
            event?.preview();
        },
        eventResize: onEventResize,
        eventDrop: onEventResize,
        events: [],
    };

    const calendarOptions = reactive({
        ...defaultOptions,
        ...options,
    });

    const getModelFromEvent = (event) => {
        return collection.value?.findById(event.id);
    };

    const calendar = ref(null);
    const calendarApi = computed(() => {
        if (!calendar.value) {
            return null;
        }

        if (calendar.value.calendar) {
            return calendar.value.calendar.getApi();
        }

        return calendar.value.getApi();
    });

    const dateRange = computed({
        get() {
            if (filters.get('view') === 'timeGridWeek') {
                return [moment(calendarApi.value?.view?.currentStart), moment(calendarApi.value?.view?.currentStart).endOf('week')];
            }
            return moment(calendarApi.value?.view?.currentStart);
        },
        set(value: any) {
            if (filters.get('view') === 'timeGridDay') {
                const date = moment(value);
                calendarApi.value?.gotoDate(date.toDate());
                return;
            }

            if (filters.get('view') === 'timeGridWeek') {
                calendarApi.value?.gotoDate(value[0]);
                return;
            }

            if (filters.get('view') === 'dayGridMonth') {
                const date = moment(`${value.year}-${value.month + 1}-01`);
                calendarApi.value?.gotoDate(date.toDate());
                return;
            }

            if (filters.get('view') === 'multiMonthYear') {
                const date = moment(`${value}-01-01`);
                calendarApi.value?.gotoDate(date.toDate());
                return;
            }
        },
    });

    const refreshCollectionItem = async (item: PlanningItem) => {
        const response = await EndpointFactory.make(ModelType.PLANNING_ITEMS).retrieve(item.getId());
        if (response.data) {
            useEvent('planning:planning-item:updated', response.data);
            calendarOptions.events = collection.value?.toFullCalendarEvents();
        }
    };

    onMounted(async () => {
        await load();
    });

    return {
        calendar,
        calendarOptions,
        calendarApi,
        getModelFromEvent,
        loading,
        load,
        filters,
        collection,
        refreshCollectionItem,
        calendarEvents,
        getWorksitePhasesForDay,
        getTasksForDay,
        getCalendarEventsForUserAndDay,
        dateRange,
    };
}
